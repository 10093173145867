import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'


import styles from './LeftMenu.module.css'

import { MdMenu, MdDashboard, MdLocationPin, MdOutlineAnalytics, MdPermContactCalendar } from "react-icons/md";
import { RiBuildingLine, RiExchangeBoxLine } from "react-icons/ri";
import { FaShoppingBag, FaListAlt, FaCalendarAlt, FaUsers, FaWpforms, FaHistory } from "react-icons/fa";
import { FiLogOut } from 'react-icons/fi';
import AuthContext from '../../store/auth-context';
import ActiveStoreSelector from './ActiveStoreSelector';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import { IoBagAdd } from 'react-icons/io5';


const LeftMenu = (props) => {
    const { isOpen, toggler } = props
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [isStoreSelectorOpen, setIsStoreSelectorOpen] = useState(false)
    const storeSelectorHandler = (k) => {
        setIsStoreSelectorOpen(false)
        asCtx.changeStore(k)
    }

    return (
        <div className={styles.mainWrapper} id="leftMenuId">
            {isStoreSelectorOpen === true && <ActiveStoreSelector isOpen={isStoreSelectorOpen} onClose={() => setIsStoreSelectorOpen(false)} onSuccess={storeSelectorHandler} />}
            <div>
                <div className={styles.hamLogoWrapper}>
                    <div className={styles.menuIcon} onClick={() => toggler()} >
                        <MdMenu size={24} />
                    </div>
                    {isOpen && <div className={styles.logoWrapper}>
                        <img src="https://lokacipro.s3.ap-south-1.amazonaws.com/clients/rajeshwariayurdhama/logos/crm-logo.png" />
                    </div>}
                </div>
                <div>
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/overview`) &&
                        <Link to={`/overview`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={styles.eachNavContainer}>
                                <MdDashboard size={isOpen ? 20 : 22} color="#898989" />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Overview</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/sessions`) &&
                        <Link to={`/sessions`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={styles.eachNavContainer}>
                                <IoBagAdd size={isOpen ? 20 : 22} color="#898989" />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Bookings / Sessions</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/bookings`) &&
                        <Link to={`/bookings`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={styles.eachNavContainer}>
                                <FaHistory size={isOpen ? 20 : 22} color="#898989" />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Bookings History</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }


                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/deals`) &&
                        <Link to={`/deals`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={styles.eachNavContainer}>
                                <FaShoppingBag size={isOpen ? 20 : 22} color="#898989" />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Deals</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/manage/clients`) &&
                        <Link to={`/manage/clients`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={styles.eachNavContainer}>
                                <MdPermContactCalendar size={isOpen ? 20 : 22} color="#898989" />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Clients</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/invoices`) &&
                        <Link to={`/invoices`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={styles.eachNavContainer}>
                                <MdOutlineAnalytics size={isOpen ? 20 : 22} color="#898989" />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Invoices</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/calendar`) &&
                        <Link to={`/calendar`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={styles.eachNavContainer}>
                                <FaCalendarAlt size={isOpen ? 20 : 22} color="#898989" />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Calendar</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/manage/price-list`) &&
                        <Link to={`/manage/price-list`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={styles.eachNavContainer}>
                                <FaListAlt size={isOpen ? 20 : 22} color="#898989" />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Price List</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/manage-stores`) &&
                        <Link to={`/manage-stores`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={styles.eachNavContainer}>
                                <RiBuildingLine size={isOpen ? 20 : 22} color="#898989" />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Locations / Stores</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/manage/employees`) &&
                        <Link to={`/manage/employees`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={styles.eachNavContainer}>
                                <FaUsers size={isOpen ? 20 : 22} color="#898989" />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Users</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }

                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/user-registration-forms`) &&
                        <Link to={`/user-registration-forms`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={styles.eachNavContainer}>
                                <FaWpforms size={isOpen ? 20 : 22} color="#898989" />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Registration Forms</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }

                </div>
            </div>
            <div className={styles.hfWrapper}>
                {/* {JSON.parse(authCtx?.loginData)?.storesData?.length > 1 && */}
                <div className={styles.changeActiveStore}
                    onClick={() => setIsStoreSelectorOpen(true)}
                >
                    <div className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}
                    >
                        <div className={styles.eachNavContainer}>
                            <RiExchangeBoxLine size={isOpen ? 20 : 22} color="#00000090" />
                            {isOpen && (
                                <>
                                    <div className={styles.navIcon}></div>
                                    <div className={styles.navTxt}>Change Store</div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {/* } */}

                <div className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}
                    onClick={() => authCtx.logout()}
                >
                    <div className={styles.eachNavContainer}>
                        <FiLogOut size={isOpen ? 20 : 22} color="#898989" />
                        {isOpen && (
                            <>
                                <div className={styles.navIcon}></div>
                                <div className={styles.navTxt}>Logout</div>
                            </>
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LeftMenu