import React, { useContext, useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from '../Users/AddNewClient.module.css'
import { Link } from 'react-router-dom'
import { getAge } from '../../Helper/calculations';

const AddNewClient = props => {
    const authCtx = useContext(AuthContext);
    const { isOpen, closeModal, assignClient } = props

    const numbers = Array.from({ length: 30 }, (_, i) => i + 1);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
    const [creationStatus, setCreationStatus] = useState('NOT_UPDATING'); // UPDATING
    const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false)
    const [clientToAssign, setClientToAssign] = useState(null)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [dob, setDob] = useState('')
    window.onclick = function (event) {
        if (event.target.id === "addNewClientForm") {
            closeModal();
        }

        if (event.target.id === "filtersPanelWrapperMUG") {
            document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft .1s forwards";
        }

    }

    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft .1s forwards";
            closeModal()
        }
    });

    const [phoneNumber, setPhoneNumber] = useState({
        otp: props.phone
    });
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [gender, setGender] = useState('')
    const [address, setAddress] = useState('')
    const [dayAnniversary, setDayAnniversary] = useState('')
    const [monthAnniversary, setMonthAnniversary] = useState('')

    const phoneNumberHandler = (otp) => {
        setPhoneNumber({ otp });
    }

    const isReadyHandler = () => {
        if (phoneNumber?.otp?.length === 10) {
            if (firstName?.length > 1) {
                if (gender !== '') {
                    setIsReadyToSubmit(true)
                } else {
                    setIsReadyToSubmit(false)
                }
            } else {
                setIsReadyToSubmit(false)
            }
        } else {
            setIsReadyToSubmit(false)
        }
    }

    useEffect(() => {
        isReadyHandler()
    }, [phoneNumber, firstName, gender])

    useEffect(() => {
        setPhoneNumber({
            otp: props.phone
        })
    }, [props.phone])

    useEffect(() => {
        setPhoneNumber({ otp: '' })
        setIsAlreadyRegistered(false)
        setFirstName('')
        setMiddleName('')
        setLastName('')
        setGender('')
        setAddress('')
    }, [isOpen])


    const assignClientHandler = (k) => {
        assignClient(k)
        closeModal()
    }

    const addNewClientHandler = async () => {
        setCreationStatus('UPDATING')

        console.log(phoneNumber.otp, firstName, middleName, lastName, gender, address, dayAnniversary, monthAnniversary)

        const addNewClientResponse = await fetch(BASE_URL_API + "addNewClient",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    mobile: phoneNumber.otp,
                    firstName: firstName,
                    middleName: middleName,
                    lastName: lastName,
                    gender: gender,
                    address: address,
                    dob: dob
                })
            });

        if (!addNewClientResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addNewClientRespo = await addNewClientResponse.json();
            if (addNewClientRespo.status === 'success') {
                if (assignClient !== undefined) {
                    assignClient(addNewClientRespo.response)
                    closeModal()
                }
                toast.success("Client successfully onboarded!")
            } else {
                if (addNewClientRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else if (addNewClientRespo.message === 'user already registered') {
                    toast(`Client is already registered!`)
                    if (assignClient !== undefined) {
                        setIsAlreadyRegistered(true)
                        setClientToAssign(addNewClientRespo?.response)
                        // assignClient(addNewClientRespo.response)
                        // closeModal()
                    }
                }
            }

            console.log(addNewClientRespo);
        }

        setCreationStatus('NOT_UPDATING')

    }


    const [ageYear, setAgeYear] = useState('')
    const [ageMonth, setAgeMonth] = useState('')

    const [years, setYears] = useState([]);

    useEffect(() => {
        const yearsArray = [];

        for (let year = 120; year >= 0; year--) {
            yearsArray.push(year);
        }

        setYears(yearsArray);
    }, []);

    const getPastDate = (year, month) => {
        // Get the current date
        const currentDate = new Date();

        // Create a new Date object for the given year and month, setting the day to 1
        let date = new Date(currentDate.getFullYear() - year, month - 1, 1);

        // If the generated date is in the future, adjust the year backward by 1 year
        if (date > currentDate) {
            date.setFullYear(date.getFullYear() - 1);
        }

        // Format the date as "yyyy-MM-dd"
        const formattedDate = date.toISOString().split('T')[0];

        setDob(formattedDate);
    };

    useEffect(() => {
        getPastDate(ageYear, ageMonth)
    }, [ageYear, ageMonth])


    if (isOpen) {
        return (
            <div className={styles.mainWrapper}>
                <ToastContainer />
                <div className={styles.mainContainer}>
                    <div className={styles.filtersPanelWrapper}
                        id="addNewClientForm"
                        style={{
                            animation: `openAddNewClientPanel 0.2s forwards`
                        }}
                    >
                        <div className={styles.mainLeft}>
                            <div className={styles.bodyContent}>
                                <div className={styles.bodyTitle}>
                                    Add A New Client
                                </div>
                                {creationStatus === 'NOT_UPDATING' &&
                                    isAlreadyRegistered === false ?
                                    <div className={styles.formBody}>
                                        <div className={styles.phoneNumberWrapper}>
                                            <div className={styles.phoneTitle}>
                                                Enter 10 digit Phone number.
                                            </div>
                                            <div >
                                                <OtpInput
                                                    value={phoneNumber.otp}
                                                    numInputs={10}
                                                    shouldAutoFocus={true}
                                                    onChange={(otp) => phoneNumberHandler(otp)}
                                                    isInputNum={true}
                                                    containerStyle={styles.phoneNumberContainer}
                                                    inputStyle={styles.phoneInputBox}
                                                />
                                            </div>
                                        </div>



                                        <div className={styles.nameWrapper}>
                                            <div className={styles.eachNamePart}>
                                                <div className={styles.eachNamePartTitle}>
                                                    First Name
                                                </div>
                                                <div className={styles.eachNamePartInputWrapper}>
                                                    <input onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                                                </div>
                                            </div>
                                            <div className={styles.eachNamePart}>
                                                <div className={styles.eachNamePartTitle}>
                                                    Middle Name
                                                </div>
                                                <div className={styles.eachNamePartInputWrapper}>
                                                    <input onChange={(e) => setMiddleName(e.target.value)} value={middleName} />
                                                </div>
                                            </div>
                                            <div className={styles.eachNamePart}>
                                                <div className={styles.eachNamePartTitle}>
                                                    Last Name
                                                </div>
                                                <div className={styles.eachNamePartInputWrapper}>
                                                    <input onChange={(e) => setLastName(e.target.value)} value={lastName} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.genderWrapper}>
                                            <div className={styles.genderTitle}>
                                                Gender
                                            </div>
                                            <div className={styles.genderBtnWrapper}>
                                                <button onClick={() => setGender('Male')} className={gender === 'Male' ? styles.genderBtnSelected : styles.genderBtnDefault}>Male</button>
                                                <button onClick={() => setGender('Female')} className={gender === 'Female' ? styles.genderBtnSelected : styles.genderBtnDefault}>Female</button>
                                                <button onClick={() => setGender('LGBTQ')} className={gender === 'LGBTQ' ? styles.genderBtnSelected : styles.genderBtnDefault}>LGBTQ</button>
                                                <button onClick={() => setGender('Unknown')} className={gender === 'Unknown' ? styles.genderBtnSelected : styles.genderBtnDefault}>Unknown</button>
                                            </div>
                                        </div>

                                        <div className={styles.addressWrapper}>
                                            <div className={styles.genderTitle}>
                                                Address
                                            </div>
                                            <div className={styles.addressBoxWrapper}>
                                                <textarea onChange={(e) => setAddress(e.target.value)}
                                                    value={address}
                                                >

                                                </textarea>
                                            </div>
                                        </div>

                                        <div className={styles.ageInputWrapper}>
                                            <div className={styles.yearsWrapper}>
                                                <select onChange={(e) => setAgeYear(e.target.value)}>
                                                    <option value={null}>Select Age (Year)</option>
                                                    {years?.map((item, index) => (
                                                        <option key={index} value={item}>{item} years</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className={styles.monthsWrapper}>
                                                <select onChange={(e) => setAgeMonth(e.target.value)}>
                                                    <option value={null}>Select Age (Month)</option>
                                                    <option value={0} >0 Month</option>
                                                    <option value={1} >1 Month</option>
                                                    <option value={2} >2 Months</option>
                                                    <option value={3} >3 Months</option>
                                                    <option value={4} >4 Months</option>
                                                    <option value={5} >5 Months</option>
                                                    <option value={6} >6 Months</option>
                                                    <option value={7} >7 Months</option>
                                                    <option value={8} >8 Months</option>
                                                    <option value={9} >9 Months</option>
                                                    <option value={10} >10 Months</option>
                                                    <option value={11} >11 Months</option>
                                                    <option value={12} >12 Months</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className={styles.dobWrapper}>
                                            <input placeholder="DOB" type="date" value={dob} onChange={(e) => setDob(e.target.value)} />
                                        </div>

                                        {isAlreadyRegistered === true ?
                                            <div className={styles.eachOptionWrapper}>
                                                <button className={styles.addNowBtn}
                                                    onClick={() => assignClientHandler(clientToAssign)}
                                                >Assign Client</button>
                                            </div>
                                            :
                                            <div className={styles.eachOptionWrapper}>
                                                {isReadyToSubmit === true ?
                                                    <button className={styles.addNowBtn}
                                                        onClick={() => addNewClientHandler()}
                                                    >Add Now</button>
                                                    :
                                                    <button className={styles.addNowBtnDull}>Add Now</button>
                                                }
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className={styles.formBody}>
                                        <div className={styles.clientDetails}>
                                            <div className={styles.clientName}>
                                                {clientToAssign?.client_name}
                                            </div>
                                            <div className={styles.address}>
                                                {clientToAssign?.client_address}
                                            </div>
                                            <div className={styles.phone} >
                                                <div>{clientToAssign?.countryCode}</div>
                                                <div>{clientToAssign?.mobile}</div>
                                            </div>
                                        </div>
                                        <div className={styles.alreadyRegistered}>
                                            Already registered.
                                        </div>
                                        <div>
                                            <div className={styles.memberCount} >
                                                {Array.isArray(clientToAssign?.relatives && JSON.parse(clientToAssign?.relatives)) ? JSON.parse(clientToAssign?.relatives)?.length : 0} family members found!
                                            </div>
                                            <div className={styles.relativesWrapper}>
                                                {(clientToAssign?.relatives && (() => {
                                                    try {
                                                        const parsedRelatives = JSON.parse(clientToAssign.relatives);
                                                        return Array.isArray(parsedRelatives) ? parsedRelatives : [];
                                                    } catch (error) {
                                                        console.error("Error parsing relatives:", error);
                                                        return [];
                                                    }
                                                })())?.map((itemRelative, index) => (
                                                    <div key={index} className={styles.eachRelative}>
                                                        <div className={styles.erUpper}>
                                                            <div className={styles.rFullName}>
                                                                {itemRelative?.salutation} {`${itemRelative?.firstName} ${itemRelative?.middleName} ${itemRelative?.lastName}`.trim()}
                                                            </div>
                                                            <div className={styles.rAge}>
                                                                {getAge(itemRelative?.dateOfBirth)} years old
                                                            </div>
                                                        </div>
                                                        <div className={styles.erLower}>
                                                            <div className={styles.rGender}>
                                                                {itemRelative?.gender}
                                                            </div>
                                                            <div className={styles.relationToFamilyHead}>
                                                                <div className={styles.rtfh}>
                                                                    {itemRelative?.relationToFamilyHead}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                            <div className={styles.alreadyRegistered}>
                                                <Link to={`/user/${clientToAssign?.clientStoreRelationIdentifier}`}>Do you want to add family member / relatives?</Link>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {creationStatus === 'UPDATING' &&
                                    <div className={styles.formBody}>
                                        <div className={styles.phoneNumberWrapper}>
                                            <div className={styles.phoneTitle}>
                                                Hold On!
                                            </div>
                                            <div >

                                            </div>
                                        </div>
                                        <div className={styles.phoneNumberWrapper}>
                                            <div className={styles.phoneTitle}>
                                                Please wait for a moment,
                                            </div>
                                            <div >

                                            </div>
                                        </div>
                                        <div>
                                            Adding now ...
                                        </div>
                                        <div className={styles.eachOptionWrapper}>
                                            <button className={styles.addNowBtn}>
                                                Adding...
                                            </button>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            null
        )
    }
}

export default AddNewClient