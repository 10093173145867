import React, { useContext, useEffect, useState } from 'react'
import styles from './EventCalendar.module.css'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';

import moment from 'moment'
import ActiveStoreContext from '../../store/ActiveStoreContext';
import StoresHeader from '../../Components/Stores/StoresHeader';
import NewFutureBooking from './NewFutureBooking';
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import ViewFutureBooking from './ViewFutureBooking';

const localizer = momentLocalizer(moment)

const EventCalendar = () => {
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [activeSlotInfo, setActiveSlotInfo] = useState('')

    const [isNewBookingOpen, setIsNewBookingOpen] = useState(false)
    const [isViewBookingOpen, setIsViewBookingOpen] = useState(false)
    const [isLoadingEvent, setIsLoadingEvent] = useState(false)
    const [activeViewBooking, setActiveViewBooking] = useState(null)

    const [myEventsList, setMyEventsList] = useState([
        {
            id: 0,
            title: 'All Day Event very long title',
            allDay: true,
            start: new Date(2024, 9, 0),
            end: new Date(2024, 9, 1),
        },
        {
            id: 55,
            title: 'New Event very long title',
            allDay: false,
            start: new Date(2024, 9, 1, 11, 0, 0),
            end: new Date(2024, 9, 1, 13, 0, 0),
        },
        {
            id: 1,
            title: 'Long Event',
            start: new Date(2024, 0, 7),
            end: new Date(2024, 0, 10),
        },

        {
            id: 2,
            title: 'DTS STARTS',
            start: new Date(2024, 9, 18, 0, 0, 0),
            end: new Date(2024, 9, 23, 0, 0, 0),
        },

        {
            id: 3,
            title: 'DTS ENDS',
            start: new Date(2024, 10, 6, 0, 0, 0),
            end: new Date(2024, 10, 13, 0, 0, 0),
        },

        {
            id: 4,
            title: 'Some Event',
            start: new Date(2024, 3, 9, 0, 0, 0),
            end: new Date(2024, 3, 9, 0, 0, 0),
            allDay: true,
        }
    ])

    const handleEventClick = (event) => {
        // Add your custom logic here
        setIsViewBookingOpen(true)
        setActiveViewBooking(event)
    };

    const handleSlotClick = (slotInfo) => {
        // console.log(slotInfo)
        setIsNewBookingOpen(true)
        setActiveSlotInfo(slotInfo)
        // const title = prompt('Enter new event title:');
        // if (title) {
        //     // Add new event to the list
        //     const newEvent = {
        //         id: myEventsList.length + 1, // Generate a new unique id
        //         title,
        //         start: slotInfo.start, // Slot's start time/date
        //         end: slotInfo.end,     // Slot's end time/date
        //         allDay: slotInfo.slots.length === 1, // If the slot spans a full day
        //     };
        //     setMyEventsList([...myEventsList, newEvent]); // Update the state
        // }
    };

    const postEventAddition = () => {
        toast(`Event created Successfully!`)
        setIsNewBookingOpen(false)
        getReservations()
    }
    
    const onDeletionReservation = () => {
        toast(`Successfully deleted the reservation!`)
        setIsViewBookingOpen(false)
        getReservations()
    }

    const getReservations = async () => {
        setIsLoadingEvent(true)
        const getReservationsResponse = await fetch(BASE_URL_API + "getReservations",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getReservationsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getReservationsRespo = await getReservationsResponse.json()
            if (getReservationsRespo?.status === "success") {
                setMyEventsList(transformEvents(getReservationsRespo?.response))
            } else {
                if (getReservationsRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                    console.log(getReservationsRespo)
                }
            }
        }
        setIsLoadingEvent(false)
    }
    
    useEffect(() => {
        getReservations()
    }, [activeStore?.storeIdentifier])
    


    const transformEvents = (inputArray) => {
        return inputArray.map((event, index) => {
            // Parse the start date string
            const startDate = new Date(event.start.replace(' ', 'T'));
            // Parse the end date string, handle "0000-00-00 00:00:00" case
            const endDate = event.end === "0000-00-00 00:00:00"
                ? new Date(startDate.getTime() + 23 * 60 * 60 * 1000)  // Default to one day after start
                : new Date(event.end.replace(' ', 'T'));

            // Check if it's an all-day event (end is 00:00:00 or same day as start)
            const allDay = event.end === "0000-00-00 00:00:00";

            return {
                id: index,
                eventIdentifier : event?.eventIdentifier,
                title: event.title || 'Untitled Event',
                allDay: allDay,
                start: startDate,
                end: endDate,
                servicesNotes : event.servicesNotes,
                remarks : event.remarks,
                clientName : event.clientName
            };
        });
    }


    if (activeStore === null) {
        return (
            <div className={styles.mainWrapper}>
                <StoresHeader />
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            Please select a store!
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.actionWrapper}>
                    <button className={styles.newBookingBtn} onClick={() => setIsNewBookingOpen(true)} >New Booking+</button>
                </div>
                <Calendar
                    localizer={localizer}
                    events={myEventsList}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 600 }}
                    onSelectEvent={handleEventClick} // Add onSelectEvent prop
                    selectable
                    onSelectSlot={handleSlotClick} // Click on time slots or day slots
                />
            </div>
            {isNewBookingOpen && <NewFutureBooking isOpen={isNewBookingOpen} onClose={() => setIsNewBookingOpen(false)} onSuccess={() => postEventAddition()} slotInfo={activeSlotInfo} />}
            {isViewBookingOpen && <ViewFutureBooking isOpen={isViewBookingOpen} onClose={() => setIsViewBookingOpen(false)} onDeletionReservation={() => onDeletionReservation()} item={activeViewBooking} />}
        </div>
    )
}

export default EventCalendar