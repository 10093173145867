import React, { useContext, useEffect, useState } from 'react';
import styles from './ViewInvoice.module.css'
import { useParams, useNavigate } from 'react-router-dom';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import generatePDF, { Margin, Resolution, usePDF } from 'react-to-pdf';
import { IoMdCloudDownload } from 'react-icons/io';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import { dateDifference, numberToWords } from '../../Helper/calculations';
import InvoiceBrandLogo from './InvoiceBrandLogo'
import { BiArrowBack } from 'react-icons/bi';
import { FaToggleOff, FaToggleOn } from 'react-icons/fa';
import { isEqual } from 'lodash';


const ViewInvoice = () => {
    const { invoiceIdentifier } = useParams(); // Get the invoiceIdentifier from URL
    const nav = useNavigate()
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    window.onclick = event => event.target.id === "vimctopid" ? nav(`/invoices`) : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            nav(`/invoices`)
        }
    });

    const { toPDF, targetRef } = usePDF({ filename: `${invoiceIdentifier}.pdf` });
    const [hasEdited, setHasEdited] = useState(false)

    const options = {
        resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            // format: 'letter',
            // default is 'portrait'
            // orientation: 'landscape',
        }

    };

    const [invoiceData, setInvoiceData] = useState(null)
    const [invoiceDataToUpdate, setInvoiceDataToUpdate] = useState(null)

    const getTargetElement = () => document.getElementById('content-id');

    const downloadPdfHandler = () => {
        const contentElement = document.getElementById("content-id");

        // Temporarily expand the element to its full height
        const originalWidth = contentElement.style.width;
        const originalHeight = contentElement.style.height;

        // Set width and height to auto to capture all content
        contentElement.style.width = '8.27in'; // A4 width in inches
        contentElement.style.height = 'auto';

        // Generate the PDF with full content
        generatePDF(getTargetElement, options).then(() => {
            // Restore original dimensions
            contentElement.style.width = originalWidth;
            contentElement.style.height = originalHeight;
        }).catch(error => {
            console.error('Error generating PDF:', error);
        });
    };

    const updatePdfHandler = async () => {
        console.log(invoiceDataToUpdate)
        setHasEdited(false)
    }

    const getInvoiceDetails = async () => {
        const getInvoiceDetailsResponse = await fetch(BASE_URL_API + "getInvoiceDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    invoiceKey: invoiceIdentifier
                })
            });

        if (!getInvoiceDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getInvoiceDetailsRespo = await getInvoiceDetailsResponse.json()
            if (getInvoiceDetailsRespo.status === "success") {
                setInvoiceData(getInvoiceDetailsRespo.response)
                setInvoiceDataToUpdate(getInvoiceDetailsRespo.response)
            } else {
                if (getInvoiceDetailsRespo?.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            console.log(getInvoiceDetailsRespo)
        }
    }

    useEffect(() => {
        getInvoiceDetails()
    }, [invoiceIdentifier])

    const sumQuantities = (items) => {
        return items?.reduce((sum, item) => sum + parseInt(item?.quantity, 10), 0);
    }

    useEffect(() => {
        if (isEqual(invoiceData, invoiceDataToUpdate)) {
            setHasEdited(false);
        } else {
            setHasEdited(true);
        }
    }, [invoiceDataToUpdate]);

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} id="vimctopid">
                <div className={styles.editingWrapper}>
                    <div className={styles.editingHeader}>
                        <div className={styles.backIcon} 
                            onClick={() => nav(`/invoices`)}
                        >
                            <BiArrowBack size={24} />
                        </div>
                        <div>
                            Edit Invoice
                        </div>
                    </div>
                    <div className={styles.paramWrapper}>
                        <div className={styles.eachParamWrapper}>
                            <div className={styles.fieldTitle}>Show Brand GST Details</div>
                            <div
                                className={styles.toggleIcon}
                                onClick={() => {
                                    setInvoiceDataToUpdate((prevDetails) => ({
                                        ...prevDetails,
                                        showBrandGst: invoiceDataToUpdate?.showBrandGst === "1" ? '0' : '1'
                                    }));
                                }}
                            >
                                {invoiceDataToUpdate?.showBrandGst === "1" ?
                                    <FaToggleOn size={30} color="#07Adff" />
                                    :
                                    <FaToggleOff size={30} color="#818181" />
                                }
                            </div>
                        </div>
                        <div className={styles.eachParamWrapper}>
                            <div className={styles.fieldTitle}>Show GST Table</div>
                            <div
                                className={styles.toggleIcon}
                                onClick={() => {
                                    setInvoiceDataToUpdate((prevDetails) => ({
                                        ...prevDetails,
                                        showTaxTableInInvoice: invoiceDataToUpdate?.showTaxTableInInvoice === "1" ? '0' : '1'
                                    }));
                                }}
                            >
                                {invoiceDataToUpdate?.showTaxTableInInvoice === "1" ?
                                    <FaToggleOn size={30} color="#07Adff" />
                                    :
                                    <FaToggleOff size={30} color="#818181" />
                                }
                            </div>
                        </div>
                        <div className={styles.tncTextEditable}>
                            <div className={styles.tncTitleEditable}>
                                Terms & Conditions:
                            </div>
                            <textarea
                                placeholder="Please mention terms and conditions..."
                                rows={8}
                                onChange={(e) => {
                                    const textareaValue = e.target.value;

                                    // Replace new lines (\n) with <br> tags for proper HTML rendering
                                    const htmlContent = textareaValue.replace(/\n/g, "<br>");

                                    setInvoiceDataToUpdate((prevDetails) => ({
                                        ...prevDetails,
                                        invoiceTnc: htmlContent // Store with <br> tags
                                    }));
                                }}
                                value={invoiceDataToUpdate?.invoiceTnc?.replace(/<br>/g, '\n')} // Convert <br> back to newlines for textarea display
                            >
                            </textarea>
                        </div>
                    </div>
                </div>
                <div className={`${styles.mainContent} ${styles.active}`}>
                    <div className={styles.mainHeader}>
                        Invoice Preview
                    </div>
                    <div className={styles.mainBody}>
                        <div className={styles.invoiceWrapper}>
                            <div className={styles.invoiceContainer}
                                ref={targetRef}
                                id="content-id"
                            >
                                <div className={styles.topWrapper}>
                                    <div className={styles.businessWrapper}>
                                        <InvoiceBrandLogo />
                                        <div className={styles.storeName}>
                                            {activeStore?.storeName}
                                        </div>
                                        <div className={styles.storeAddress}>
                                            {activeStore?.storeAddress}
                                        </div>
                                        <div className={styles.storePoc}>
                                            {[
                                                activeStore?.storeEmail,
                                                activeStore?.storePrimaryPhone,
                                                activeStore?.storeSecondaryPhone,
                                                activeStore?.storeTertiaryPhone
                                            ]
                                                .filter(Boolean)  
                                                .join(", ")}      
                                        </div>

                                        {invoiceDataToUpdate?.showBrandGst === "1" &&
                                            <div className={styles.gstNumber}>
                                                GSTIN : 29ACXPL2065P1ZL
                                            </div>
                                        }
                                        <div className={styles.licenseNo}>
                                            License No.
                                        </div>
                                    </div>
                                    <div className={styles.clientWrapper}>
                                        <div>
                                            <div className={styles.clientName}>
                                                To&nbsp;
                                                {
                                                    (() => {
                                                        const age = dateDifference(invoiceDataToUpdate?.client_birthday);
                                                        const years = age?.years;

                                                        if (years < 16) {
                                                            return 'Baby';
                                                        } else if (invoiceData?.gender === 'Male') {
                                                            return 'Mr.';
                                                        } else if (invoiceData?.gender === 'Female') {
                                                            return 'Ms.';
                                                        }
                                                        return ''; // For cases where gender is not specified
                                                    })()
                                                } {invoiceDataToUpdate?.clientName}
                                            </div>

                                            <div className={styles.age}>
                                                {(() => {
                                                    const age = dateDifference(invoiceDataToUpdate?.client_birthday);
                                                    const years = age?.years;
                                                    const months = age?.months;

                                                    if (years || months) {
                                                        return `${years ? `${years} years` : ''}${years && months ? ' & ' : ''}${months ? `${months} months old` : ''}`;
                                                    }
                                                    return ''; // Return an empty string if both years and months are not available
                                                })()}

                                                {invoiceDataToUpdate?.gender ? `, ${invoiceDataToUpdate.gender}` : ''}
                                            </div>

                                            <div className={styles.phoneNumber}>
                                                +91-{invoiceDataToUpdate?.clientPhone}{invoiceDataToUpdate?.alternateNumber ? `, ${invoiceDataToUpdate.alternateNumber}` : ''}
                                            </div>
                                            <div className={styles.whatsAppNumber} >
                                                {invoiceDataToUpdate?.whatsAppNumber ? `, ${invoiceDataToUpdate.whatsAppNumber}` : ''}
                                            </div>
                                        </div>
                                        <div>

                                            {/* <div className={styles.gstNumber}>
                                                GSTIN :
                                            </div>
                                            <div className={styles.licenseNo}>
                                                License No.
                                            </div> */}
                                        </div>

                                    </div>
                                    <div className={styles.taxWrapper}>
                                        <div className={styles.taxTitle} >
                                            <div>
                                                TAX INVOICE
                                            </div>
                                        </div>
                                        <div className={styles.taxDetails}>
                                            <div className={styles.eachTdRow}>
                                                <div className={styles.etrTitle}>Bill No:</div>
                                                <div className={styles.etrValue}>S/24-25/{invoiceDataToUpdate?.billCount}</div>
                                            </div>
                                            <div className={styles.eachTdRow}>
                                                <div className={styles.etrTitle}>Date</div>
                                                <div className={styles.etrValue}>{invoiceDataToUpdate?.createdOn?.substring(0, 10)}</div>
                                            </div>
                                            <div className={styles.eachTdRow}>
                                                <div className={styles.etrTitle}>Time</div>
                                                <div className={styles.etrValue}>{invoiceDataToUpdate?.createdOn?.substring(10, 20)}</div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className={styles.tableWrapper}>
                                    <div className={styles.tableHeader}>
                                        <div className={styles.sn}>#</div>
                                        <div className={styles.itemName}>Item Name</div>
                                        <div className={styles.batchNo}>Batch No.</div>
                                        <div className={styles.exp}>Exp</div>
                                        <div className={styles.hsn}>HSN Code</div>
                                        <div className={styles.gst}>GST%</div>
                                        <div className={styles.mrp}>MRP</div>
                                        <div className={styles.qty}>Qty.</div>
                                        <div className={styles.rate}>Rate</div>
                                        <div className={styles.amount}>Amount</div>
                                        <div className={styles.disc}>Disc.</div>
                                        <div className={styles.total}>Total</div>
                                    </div>
                                    <div className={styles.tableBody}>
                                        {invoiceData?.items?.map((item, index) => (
                                            <div key={index} className={styles.rowWrapper}>
                                                <div className={styles.sn}>{index + 1}.</div>
                                                <div className={styles.itemName}>
                                                    {item?.serviceName}
                                                    <div className={styles.serviceDuration} >
                                                        {item?.serviceDuration} {item?.serviceDurationUnit}
                                                    </div>
                                                </div>
                                                <div className={styles.batchNo}>MRP-{item?.basePrice}</div>
                                                <div className={styles.exp}>-</div>
                                                <div className={styles.hsn}>-</div>
                                                <div className={styles.gst}>12.00</div>
                                                <div className={styles.mrp}>{item?.basePrice}</div>
                                                <div className={styles.qty}>{item?.quantity}</div>
                                                <div className={styles.rate}>{item?.basePrice}</div>
                                                <div className={styles.amount}>{parseFloat(parseFloat(item?.basePrice) * parseFloat(item?.quantity)).toFixed(2)}</div>
                                                <div className={styles.disc}>{parseFloat(parseFloat(item?.totalBasePrice) - parseFloat(item?.totalDiscPrice)).toFixed(2)}</div>
                                                <div className={styles.total}>{parseFloat(parseFloat(item?.totalBasePrice).toFixed(2) * parseInt(item?.quantity)).toFixed(2)}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className={styles.totalRow}>
                                    <div className={styles.trTotal}>
                                        Total
                                    </div>
                                    <div className={styles.trTValue}>
                                        {sumQuantities(invoiceData?.items)}
                                    </div>
                                    <div className={styles.trRa}>
                                        {parseFloat(invoiceData?.servicesTotalOrgPrice).toFixed(2)}
                                    </div>
                                    <div className={styles.disc}>
                                        {parseFloat(parseFloat(invoiceData?.servicesTotalOrgPrice) - parseFloat(invoiceData?.amountToBeCollected)).toFixed(2)}
                                    </div>
                                    <div className={styles.total}>
                                        ₹{parseFloat(invoiceData?.amountToBeCollected).toFixed(2)}
                                    </div>
                                </div>

                                <div className={styles.gstNTotalWrapper}>
                                    <div className={styles.amtInWordsWrapper}>
                                        <div className={styles.amt}>
                                            Amount in Words : {numberToWords(parseInt(invoiceData?.amountToBeCollected))} rupees only.
                                        </div>
                                        {invoiceDataToUpdate?.showTaxTableInInvoice === "1" &&
                                            <div className={styles.taxTableWrapper}>
                                                <div className={styles.taxTableContainer}>
                                                    <div className={styles.ttTaxableValue}>
                                                        <div className={styles.ttwUpperBlock}>
                                                            Taxable Value
                                                        </div>
                                                        <div className={styles.ttwMiddleBlock}>
                                                            {parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)}
                                                        </div>
                                                        <div className={styles.ttwBottomBlock}>
                                                            {parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)}
                                                        </div>
                                                    </div>
                                                    <div className={styles.ttCgst}>
                                                        <div className={styles.ttwUpperBlock}>
                                                            <div className={styles.ttwubUpper}>
                                                                CGST
                                                            </div>
                                                            <div className={styles.ttwubLower}>
                                                                <div className={styles.ttwubPercent} >%</div>
                                                                <div className={styles.ttwubAmount} >Amt</div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.ttwMiddleBlock}>
                                                            <div className={styles.ttwubLower}>
                                                                <div className={styles.ttwubPercent} >6</div>
                                                                <div className={styles.ttwubAmount} >
                                                                    {parseFloat(parseFloat(invoiceData?.amountToBeCollected) - parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)).toFixed(2) / 2}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.ttwBottomBlock}>
                                                            {parseFloat(parseFloat(invoiceData?.amountToBeCollected) - parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)).toFixed(2) / 2}
                                                        </div>
                                                    </div>
                                                    <div className={styles.ttCgst}>
                                                        <div className={styles.ttwUpperBlock}>
                                                            <div className={styles.ttwubUpper}>
                                                                SGST
                                                            </div>
                                                            <div className={styles.ttwubLower}>
                                                                <div className={styles.ttwubPercent} >%</div>
                                                                <div className={styles.ttwubAmount} >Amt</div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.ttwMiddleBlock}>
                                                            <div className={styles.ttwubLower}>
                                                                <div className={styles.ttwubPercent} >6</div>
                                                                <div className={styles.ttwubAmount} >
                                                                    {parseFloat(parseFloat(invoiceData?.amountToBeCollected) - parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)).toFixed(2) / 2}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.ttwBottomBlock}>
                                                            {parseFloat(parseFloat(invoiceData?.amountToBeCollected) - parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)).toFixed(2) / 2}
                                                        </div>
                                                    </div>
                                                    <div className={styles.ttCgst}>
                                                        <div className={styles.ttwUpperBlock}>
                                                            <div className={styles.ttwubUpper}>
                                                                IGST
                                                            </div>
                                                            <div className={styles.ttwubLower}>
                                                                <div className={styles.ttwubPercent} >%</div>
                                                                <div className={styles.ttwubAmount} >Amt</div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.ttwMiddleBlock}>
                                                            <div className={styles.ttwubLower}>
                                                                <div className={styles.ttwubPercent} >0</div>
                                                                <div className={styles.ttwubAmount} >.00</div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.ttwBottomBlock}></div>
                                                    </div>
                                                    <div className={styles.ttTgst}>
                                                        <div className={styles.ttwUpperBlock}>
                                                            Total GST
                                                        </div>
                                                        <div className={styles.ttwMiddleBlock}>
                                                            {parseFloat(parseFloat(invoiceData?.amountToBeCollected) - parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)).toFixed(2)}
                                                        </div>
                                                        <div className={styles.ttwBottomBlock}>
                                                            {parseFloat(parseFloat(invoiceData?.amountToBeCollected) - parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)).toFixed(2)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className={styles.gtWrapper}>
                                        <div className={styles.gtBeforeTax}>
                                            <div className={styles.gtTag}>Before Tax : </div>
                                            <div className={styles.gtVal}>
                                                {parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)}
                                            </div>
                                        </div>
                                        <div className={styles.totalGst}>
                                            <div className={styles.gtTag}>Total GST : </div>
                                            <div className={styles.gtVal}>
                                                {parseFloat(parseFloat(invoiceData?.amountToBeCollected) - parseFloat(parseFloat(invoiceData?.amountToBeCollected) * (1 - 0.12)).toFixed(2)).toFixed(2)}
                                            </div>
                                        </div>
                                        <div className={styles.gtDiscount}>
                                            <div className={styles.gtTag}>Discount : </div>
                                            <div className={styles.gtVal}>{parseFloat(parseFloat(invoiceData?.servicesTotalOrgPrice) - parseFloat(invoiceData?.servicesTotalDiscountedPrice)).toFixed(2)}</div>
                                        </div>
                                        <div className={styles.gtRound}>
                                            <div className={styles.gtTag}>Round Off : </div>
                                            <div className={styles.gtVal}>0</div>
                                        </div>
                                        <div className={styles.gtNetAmt}>
                                            <div className={styles.gtTag}>Net Amount : </div>
                                            <div className={styles.gtVal}>{invoiceData?.amountToBeCollected}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.tncWrapper}>
                                    <div className={styles.tnc}>
                                        <div className={styles.tncTitle}>
                                            Terms & Conditions :
                                        </div>
                                        <div className={styles.tncText}
                                            dangerouslySetInnerHTML={{ __html: invoiceDataToUpdate?.invoiceTnc }}
                                        >
                                        </div>
                                    </div>
                                    <div className={styles.storeAuthName}>
                                        <div className={styles.forStoreName}>For {activeStore?.storeName}</div>
                                        <div>{invoiceData?.createdBy}</div>
                                        <div className={styles.authSignatory}>Authorized Signatory</div>
                                    </div>
                                </div>

                                <div className={styles.disclaimerline}>
                                    This is a system generated invoice. You can use {invoiceIdentifier} key to track in future.
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className={styles.mainFooter}>
                        {hasEdited === true ?
                            <button onClick={() => updatePdfHandler()} className={styles.downloadPdfBtn} > <IoMdCloudDownload size={18} />&nbsp;Update Invoice</button>
                            :
                            <button onClick={() => downloadPdfHandler()} className={styles.downloadPdfBtn} > <IoMdCloudDownload size={18} />&nbsp;Download Pdf</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewInvoice;
